import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { MakeAxiosPaginatedResponse, MakeAxiosResponse } from '../../Service.types'
import { PriceOffer } from '../order/OrderService.types'
import {
    PriceOfferAddVariantRequest,
    PriceOfferCreateRequest,
    PriceOfferDelivery,
    PriceOfferFullData,
    PriceOfferGraphicTemplate,
    PriceOfferGraphicTemplatePosition,
    PriceOfferGraphicTemplateUpdateRequest,
    PriceOfferLayout,
    PriceOfferSaveLayoutRequest,
    PriceOfferShort,
    PriceOfferStepOneRequest,
    PriceOfferStepTwoDetail,
    PriceOfferStepTwoRequest
} from './PriceOffer.types'
import { PriceOfferSendToProductionType } from '../../../hooks/api/price-offer/usePriceOfferSendToProduction'
import { last } from 'lodash-es'
import { removeUndefinedValuesFromObject, undefinedValuesAsNullValues } from '../../../utils/ObjectHelper'
import { serialize } from 'object-to-formdata'

export const getPriceOfferPage = async (
    axiosInstance: AxiosInstance,
    query: string,
    config: AxiosRequestConfig = {}
): Promise<MakeAxiosPaginatedResponse<PriceOfferShort>> => {
    const { data } = await axiosInstance.get<MakeAxiosPaginatedResponse<PriceOfferShort>>(
        `/price-offer${query}`,
        config
    )
    return data
}

export const getPriceOfferStepData = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {},
    isForceUpdateToStepOne?: boolean,
    isPriceOfferDetail?: boolean
): Promise<PriceOfferFullData | PriceOfferStepTwoDetail> => {
    let url = `/price-offer/${id}`
    if (isForceUpdateToStepOne) {
        url += '?force_update_to_step1=1'
    }
    if (isPriceOfferDetail) {
        url += '?return_response=step_3'
    }

    const {
        data: { data: priceOffer }
    } = await axiosInstance.get<MakeAxiosResponse<PriceOfferFullData | PriceOfferStepTwoDetail>>(url, config)
    return priceOffer
}

export const getPriceOfferLayout = async (
    axiosInstance: AxiosInstance,
    id: number,
    position: PriceOfferGraphicTemplatePosition,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferLayout> => {
    const {
        data: { data: priceOfferLayout }
    } = await axiosInstance.get<MakeAxiosResponse<PriceOfferLayout>>(`/price-offer/${id}/layout/${position}`, config)
    return priceOfferLayout
}

export const getPriceOfferFile = async (
    axiosInstance: AxiosInstance,
    fileUrl: string,
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: config.responseType || 'blob'
    }

    if (fileUrl.startsWith('/api')) {
        fileUrl = fileUrl.replace('/api', '')
    } else if (!fileUrl.startsWith('http')) {
        fileUrl = `/price-offer/layout-file/${last(fileUrl.split('/'))}`
    }

    const { data } = await axiosInstance.get<Blob>(fileUrl, config)

    return data
}

export const createPriceOffer = async (
    axiosInstance: AxiosInstance,
    request: PriceOfferCreateRequest,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferFullData> => {
    const {
        data: { data: priceOffer }
    } = await axiosInstance.post<MakeAxiosResponse<PriceOfferFullData>>(
        '/price-offer',
        {
            ...request,
            orders: request.orders.map(item => {
                return {
                    id: item
                }
            })
        },
        config
    )
    return priceOffer
}

export const createPriceOfferVariant = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: PriceOfferAddVariantRequest,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferFullData> => {
    const {
        data: { data: priceOffer }
    } = await axiosInstance.post<MakeAxiosResponse<PriceOfferFullData>>(
        `/price-offer/${id}/add-variant`,
        request,
        config
    )
    return priceOffer
}

export const updatePriceOfferStepOne = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: PriceOfferStepOneRequest,
    config: AxiosRequestConfig = {},
    isFromStepOneToStepThree?: boolean
): Promise<PriceOfferFullData> => {
    const formData = serialize(undefinedValuesAsNullValues(request), { booleansAsIntegers: true, indices: true })
    formData.append('_method', 'patch')

    if (request.tendererLogo instanceof Blob) {
        formData.set('tendererLogo', request.tendererLogo, 'logo')
    }

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    let url = `/price-offer/${id}/step-1`
    if (isFromStepOneToStepThree) {
        url += '?return_response=step_3'
    }

    const {
        data: { data: priceOffer }
    } = await axiosInstance.post<MakeAxiosResponse<PriceOfferFullData>>(url, formData, config)
    return priceOffer
}

export const updatePriceOfferStepTwo = async (
    axiosInstance: AxiosInstance,
    id: number,
    request: PriceOfferStepTwoRequest,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferFullData | PriceOfferStepTwoDetail> => {
    const formData = serialize(undefinedValuesAsNullValues(request), { booleansAsIntegers: true, indices: true })
    formData.append('_method', 'patch')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const {
        data: { data: priceOffer }
    } = await axiosInstance.post<MakeAxiosResponse<PriceOfferFullData | PriceOfferStepTwoDetail>>(
        `/price-offer/${id}/step-2`,
        formData,
        config
    )
    return priceOffer
}

export const savePriceOfferLayout = async (
    axiosInstance: AxiosInstance,
    request: PriceOfferSaveLayoutRequest,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    const formData = serialize(undefinedValuesAsNullValues(request), { booleansAsIntegers: true, indices: true })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const { data } = await axiosInstance.post<void>('/price-offer/layout', formData, config)

    return data
}

export const savePriceOfferGraphicTemplate = async (
    axiosInstance: AxiosInstance,
    request: PriceOfferGraphicTemplateUpdateRequest,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferGraphicTemplate> => {
    const formData = serialize(undefinedValuesAsNullValues(request), { booleansAsIntegers: true, indices: true })
    formData.append('_method', 'post')

    config = {
        ...config,
        headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
        }
    }

    const {
        data: { data: graphicTemplate }
    } = await axiosInstance.post<MakeAxiosResponse<PriceOfferGraphicTemplate>>('/graphic-template', formData, config)

    return graphicTemplate
}

export const previousPriceOfferStep = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferFullData | PriceOfferStepTwoDetail> => {
    const {
        data: { data: priceOffer }
    } = await axiosInstance.patch<MakeAxiosResponse<PriceOfferFullData | PriceOfferStepTwoDetail>>(
        `/price-offer/${id}/step-back`,
        config
    )

    return priceOffer
}

export const priceOfferDelete = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    const { data } = await axiosInstance.delete<void>(`/price-offer/${id}`, config)

    return data
}

export const discardPriceOfferChanges = async (
    axiosInstance: AxiosInstance,
    id: number,
    config: AxiosRequestConfig = {}
): Promise<PriceOffer> => {
    const {
        data: { data: order }
    } = await axiosInstance.delete(`/price-offer/${id}/discard-changes`, config)
    return order
}

export const deleteOrderFromPriceOffer = async (
    axiosInstance: AxiosInstance,
    id: number,
    orderId: number,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    const { data } = await axiosInstance.delete<void>(`/price-offer/${id}/remove-order/${orderId}`, config)

    return data
}

export const addOrdersToPriceOffer = async (
    axiosInstance: AxiosInstance,
    id: number,
    orders: number[],
    config: AxiosRequestConfig = {}
): Promise<void> => {
    const { data } = await axiosInstance.patch<void>(
        `/price-offer/${id}/add-orders`,
        {
            orders: orders.map(item => {
                return {
                    id: item
                }
            })
        },
        config
    )

    return data
}

export const getPriceOfferOrderDelivery = async (
    axiosInstance: AxiosInstance,
    id: number,
    paramsString: string,
    config: AxiosRequestConfig = {}
): Promise<PriceOfferDelivery[]> => {
    config = {
        ...config,
        params: removeUndefinedValuesFromObject({
            ...(config?.params || {})
        })
    }

    let url = `price-offer/${id}/delivery-date`
    if (paramsString) {
        url += `?${paramsString}`
    }

    const {
        data: { data: orderDelivery }
    } = await axiosInstance.get<MakeAxiosResponse<PriceOfferDelivery[]>>(url, config)
    return orderDelivery
}

export const priceOfferSendToProduction = async (
    axiosInstance: AxiosInstance,
    id: number,
    orders: PriceOfferSendToProductionType[],
    config: AxiosRequestConfig = {}
): Promise<PriceOfferSendToProductionType[]> => {
    const {
        data: { data }
    } = await axiosInstance.patch(`price-offer/${id}/to-production`, { orders }, config)
    return data
}
